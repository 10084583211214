import "../styles/global.css";
import React, { useState } from 'react';
import Gallery from 'react-grid-gallery';

  function packImages(folderpath, pages) {
    const images = pages.reduce((accu, page, index) => {
       const imgObj = {};
       imgObj.index = index;
       imgObj.src = folderpath + page.Images.Image;
       imgObj.thumbnail = folderpath + page.Images.ImageSmall;
       imgObj.thumbnailWidth = 42;
       imgObj.thumbnailHeight = 60;
       imgObj.isSelected = true;
       imgObj.caption = page.Description;
      return [...accu, imgObj]; 
    }, []);
    return images;
    }

  function getSectionData(section) {
    let s = {}; 
    s.id = section.Id;
    s.parent = section.Parent;
    s.description = section.Description;
    s.pages = Array.isArray(section.Pages.Page) ? section.Pages.Page : [section.Pages.Page];
    return s;
  }

  const metaDataColumnStyle = {
    'paddingLeft': '10px',
    'display': 'flex', 
    'flexDirection': 'column',
    'paddingBottom': '16px'
  }  

  function styleSmall() {
   return ({ width: 'auto', height: 'auto', margin: '0', overflow: 'hidden' });
  }


export default ({ section, folderpath }) => { 
  const sectionData = getSectionData(section);
  return <div className="sectionDisplay">
    <div className="sectionIdContainer">
      Unterrichtsheft S. {sectionData.id}
    </div>
    <div style={metaDataColumnStyle}>             
      <Gallery 
        style={{ "display": "block" }}
        images={packImages(folderpath, sectionData.pages)}
        backdropClosesModal={true} 
        margin={4} 
        rowHeight={60}
        thumbnailStyle={styleSmall}
        />
      <div style={{ "marginTop": "6px" }}>
        {sectionData.description}
      </div>
      <div style={{ "marginTop": "6px" }}>
        Download der Unterrichtseinheit als <a href={`${folderpath}packages/${sectionData.id}.zip` }>ZIP-Datei</a>
      </div>
    </div>
  </div>
}